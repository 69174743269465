import React from 'react';
import moment from 'moment';
import { InputGroup, Dropdown, SwitchToggle } from 'ustrive-ui';
import { SwitchText } from './styled';

function GraduationSelect({
  label,
  level,
  graduation,
  setGraduation,
  enrolled,
  setEnrolled,
  yearIntervals = [
    -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2,
    3, 4,
  ],
}) {
  const graduationYears = yearIntervals.map((interval) =>
    moment().add(interval, 'year').format('YYYY'),
  );

  return (
    <InputGroup label={label} bold>
      <Dropdown id="graduation" value={graduation} onChange={setGraduation}>
        <option value="">Choose Year</option>
        {graduationYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Dropdown>
      <div className="row mt-3">
        <div className="col-md-12 d-flex align-items-center">
          <SwitchToggle checked={enrolled} onChange={setEnrolled} />
          <SwitchText>{`I am${enrolled ? '' : ' not'} currently enrolled in ${
            level === 'Work'
              ? 'high school'
              : (level || 'high school').toLowerCase()
          }.`}</SwitchText>
        </div>
      </div>
    </InputGroup>
  );
}

export default GraduationSelect;
