import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Header } from 'ustrive-ui';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import { AuthConsumer } from './providers/Auth';

import AlertCenter from './components/Layout/AlertCenter';
import { UserProvider, UserConsumer } from './providers/User';
import { MessagesProvider } from './providers/Messages';
import MainMenu from './components/Layout/MainMenu';
import TwilioContainer from './components/Layout/TwilioContainer';
import { logOut } from './helpers/parse';
import Popups from './components/Elements/Popups';
import * as Pages from './components/Layout/Pages';
import StudentOnboarding from './components/Elements/Onboarding/StudentOnboarding';
import { clarity } from 'react-microsoft-clarity';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a client
const queryClient = new QueryClient();

const CLARITY_ID = 'ij85tl2qj6';
clarity.init(CLARITY_ID);

const history = createBrowserHistory();

const trackPageView = (page) => {
  window.analytics.page(
    page,
    {},
    {
      integrations: {
        All: false,
        'Google Analytics': true,
        'Active Campaign': true,
        Mixpanel: false,
      },
    },
  );
  window.gtag('event', 'conversion', {
    send_to: 'AW-941450439/qDPyCJL7s4IDEMfJ9cAD',
  });
};

function App() {
  const [displayMenu, setDisplayMenu] = useState('none');
  const [pathname, setPathname] = useState('');

  const toggleMenu = (from, ev) => {
    ev.stopPropagation();
    setDisplayMenu(
      displayMenu === 'none' && from === 'header' ? 'block' : 'none',
    );
  };

  useEffect(() => {
    setPathname(history.location.pathname);
  }, []);

  history.listen((location) => {
    trackPageView(location.pathname);
    setDisplayMenu('none');
    setPathname(location.pathname);
  });

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <AuthConsumer>
          {(context) =>
            context.authenticated ? (
              <UserProvider>
                <UserConsumer>
                  {({ user }) =>
                    user.type === 'student' && !user.phone ? (
                      <div>
                        <StudentOnboarding user={user} />
                      </div>
                    ) : (
                      <>
                        <div id="dashboard">
                          {!context.hideNavigation && (
                            <MainMenu displayMenu={displayMenu} />
                          )}
                          <div
                            id="content"
                            onClick={(ev) => toggleMenu('content', ev)}
                          >
                            {!context.hideNavigation && (
                              <>
                                <Header
                                  pathname={pathname}
                                  title="Dashboard"
                                  user={user}
                                  logOut={logOut}
                                  enableSearch
                                  toggleMenu={(ev) => toggleMenu('header', ev)}
                                />
                              </>
                            )}
                            <MessagesProvider user={user}>
                              <TwilioContainer user={user}>
                                <div id="alerts">
                                  <AlertCenter />
                                  <Popups user={user} />
                                </div>

                                <Switch>
                                  <Route
                                    exact
                                    path="/dashboard"
                                    component={Pages.Home}
                                  />
                                  <Route
                                    exact
                                    path="/settings"
                                    component={Pages.Settings}
                                  />
                                  <Route
                                    exact
                                    path="/community"
                                    component={Pages.Community}
                                  />
                                  <Route
                                    exact
                                    path="/messages"
                                    component={Pages.Messages}
                                  />
                                  <Route
                                    exact
                                    path="/messages/:matchId"
                                    component={Pages.Messages}
                                  />
                                  <Route
                                    exact
                                    path="/goals"
                                    component={Pages.Goals}
                                  />
                                  <Route
                                    exact
                                    path="/goals/:goalId"
                                    component={Pages.Goal}
                                  />
                                  <Route
                                    exact
                                    path="/documents"
                                    component={Pages.Documents}
                                  />
                                  <Route
                                    exact
                                    path="/colleges"
                                    component={Pages.Colleges}
                                  />
                                  <Route
                                    exact
                                    path="/colleges/:collegeId"
                                    component={Pages.College}
                                  />
                                  <Route
                                    exact
                                    path="/guides"
                                    component={Pages.Guides}
                                  />
                                  <Route
                                    exact
                                    path="/guides/:guideId"
                                    component={Pages.Guides}
                                  />
                                  <Route
                                    exact
                                    path="/topics/:topicId"
                                    component={Pages.Topic}
                                  />
                                  <Route
                                    exact
                                    path="/help"
                                    component={Pages.Help}
                                  />
                                  <Route
                                    exact
                                    path="/contact"
                                    component={Pages.Contact}
                                  />
                                  {user.type === 'student' &&
                                    user.matches.length === 0 && (
                                      <Route
                                        exact
                                        path="/mentors/:id"
                                        component={Pages.Mentor}
                                      />
                                    )}
                                  {user.type === 'student' &&
                                    user.matches.length === 0 && (
                                      <Route
                                        exact
                                        path="/mentors"
                                        component={Pages.Suggestions}
                                      />
                                    )}
                                  <Route
                                    exact
                                    path="/training/:moduleId"
                                    component={Pages.Module}
                                  />
                                  <Route
                                    exact
                                    path="/video"
                                    component={Pages.Video}
                                  />
                                  <Route
                                    exact
                                    path="/feedback/:matchId"
                                    component={Pages.Feedback}
                                  />
                                  <Redirect from="/*" to="/dashboard" />
                                </Switch>
                                {/* <MessagesBubbleContainer user={user} /> */}
                              </TwilioContainer>
                            </MessagesProvider>
                          </div>
                        </div>
                      </>
                    )
                  }
                </UserConsumer>
              </UserProvider>
            ) : (
              <Switch>
                <Route exact path="/" component={Pages.Login} />
                <Route exact path="/login" component={Pages.Login} />
                <Route exact path="/forgot" component={Pages.Forgot} />
                <Route
                  exact
                  path="/unsubscribe/:notificationId"
                  component={Pages.Unsubscribe}
                />
                <Route exact path="/reset/:eventId" component={Pages.Reset} />
                <Route exact path="/students" component={Pages.Students} />
                <Route
                  exact
                  path="/students/:slug"
                  component={Pages.Students}
                />
                <Route exact path="/mentors/:slug" component={Pages.Mentors} />
                <Route
                  exact
                  path="/mentor/register/:slug"
                  component={Pages.Mentors}
                />
                <Route exact path="/terms" component={Pages.Terms} />
                <Route exact path="/privacy" component={Pages.Privacy} />
                <Route exact path="/confirm" component={Pages.Confirm} />
                <Route exact path="/colleges/:slug" component={Pages.College} />
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Redirect
                  from="/tca"
                  to={`/students/commonapp${window.location.search}`}
                />
                <Redirect from="/*" to="/" />
              </Switch>
            )
          }
        </AuthConsumer>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
