import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'ustrive-ui';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../../providers/User';
import { run } from '../../helpers/parse';
import { formatPhoneNumber } from '../../helpers/utils';
// import ReferralsBanner from '../Elements/ReferralsBanner';

const c = require('../Elements/Onboarding/VerifyPhone/content.json');

const MessageDiv = styled.div`
  margin-top: 10px;
  text-align: right;
  font-size: 14px;
`;

const apis = {
  getUser: 'getUser',
  replaceApi: '/api',
  verifyPhone: 'updateUser',
};

function AlertCenter({ location }) {
  // const { pathname } = location;
  const { user, updateUser } = useContext(UserContext);

  const [submitting, setSubmitting] = useState('');
  const [code, updateCode] = useState('');
  const [message, updateMessage] = useState('');

  const confirmVerify = useCallback(async () => {
    if (code.split('_').join('').length !== 4) {
      return updateMessage(c.lengthValidation);
    }

    try {
      setSubmitting(c.submitting);
      updateMessage('');

      const result = await run(apis.verifyPhone, {
        PIN: code,
      });

      if (result) {
        updateUser({
          ...user,
          phoneVerified: true,
        });
      } else {
        updateMessage(c.verificationError);
      }

      setSubmitting('');
    } catch (err) {
      console.error(err.message);
      updateMessage(c.codeError);
      setSubmitting('');
    }
  }, [user, updateUser, code]);

  if (
    (user.type === 'student' || user?.config?.verified) &&
    !user?.phoneVerified
  ) {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2 mt-4">
          <Alert
            className="primary"
            icon="mobile-alt"
            title={c.title}
            description={`Please enter the PIN we sent to ${formatPhoneNumber(
              user.phone,
            )} to verify your phone!`}
            buttonText="Verify PIN"
            buttonDisabled={submitting}
            buttonOnClick={confirmVerify}
            inputValue={code}
            inputOnChange={updateCode}
            inputPlaceholder={c.codePlaceholder}
          >
            {message && <MessageDiv>{message}</MessageDiv>}
          </Alert>
        </div>
      </div>
    );
  }

  if (user.unsubscribed.sms || user.unsubscribed.email) {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2 mt-4">
          <Alert
            className="primary"
            icon="bell-slash"
            title="Notifications Disabled"
            description={`Just so you know, you are currently unsubscribed from ${
              user.unsubscribed.sms && user.unsubscribed.email
                ? 'SMS & email'
                : `${user.unsubscribed.sms ? 'SMS' : 'email'}`
            } notifications.`}
            buttonText="Visit Settings"
            buttonLink="/settings"
          />
        </div>
      </div>
    );
  }

  return null;
}

AlertCenter.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(AlertCenter);
