import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { ToastAlert } from 'ustrive-ui';
import GraduationSelect from '../../../GraduationSelect';
import NavButtons from '../NavButtons';
import { StyledCard as Card, QuestionLabel } from '../styled';
import c from './content.json';

function GraduationStep({ user, onSave, goBack }) {
  const [graduation, setGraduation] = useState(
    user.academics.collegeGraduation || user.academics.graduation,
  );
  const [enrolled, setEnrolled] = useState(
    user.academics.enrolled !== undefined ? user.academics.enrolled : true,
  );

  const nextStep = (params) => {
    if (!params.graduation) {
      return toast.error(
        <ToastAlert
          title="Graduation Year"
          description="Please select your graduation year before proceeding!"
        />,
      );
    }
    onSave(params);
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            {user.noCollege ? (
              <QuestionLabel>{c.noCollege}</QuestionLabel>
            ) : (
              <QuestionLabel>
                {enrolled ? c.enrolledTitle : c.title}
              </QuestionLabel>
            )}
            <div className="row">
              <div className="col-md-12">
                <GraduationSelect
                  level={user.level}
                  graduation={graduation}
                  setGraduation={setGraduation}
                  enrolled={enrolled}
                  setEnrolled={setEnrolled}
                  yearIntervals={
                    enrolled
                      ? [0, 1, 2, 3, 4, 5]
                      : [
                          -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4,
                          -3, -2, -1, 0, 1, 2, 3, 4,
                        ]
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <NavButtons
        goBack={goBack}
        nextStep={() => nextStep({ graduation, enrolled })}
      />
    </>
  );
}

export default GraduationStep;
